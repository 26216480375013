export const vibiz_homepage_path = "/";
export const vibiz_login_path = "/login";
export const vibiz_expert_introduce_path = "/expert-introduce";
export const vibiz_home_new_path = "/tin-tuc";
export const vibiz_du_lieu_nganh_path = "/du-lieu-nganh";
export const vibiz_lien_he_path = "/lien-he";
export const vibiz_giai_phap_path = "/giai-phap";
export const vibiz_dich_vu_path = "/dich-vu";
export const vibiz_chi_tiet_bai_viet_path = "/chi-tiet-bai-viet";
export const vibiz_danh_sach_nganh_path = "/danh-sach-nganh";
export const vibiz_gioi_thieu_vibiz_path = "/gioi-thieu-chung";
export const vibiz_video_path = "/video";
export const vibiz_su_nghiep_path = "/gioi-thieu-chung/su-nghiep";
export const profile_path = "/account/profile";
export const forgot_password = "/forgot-password";
export const reset_password = "/reset_password";
export const change_password_path = "/account/change-password";
export const tag_path = "/tag";
// export const term_of_user_path = "/dieu-khoan-su-dung";
export const term_of_user_path = "/dieu-khoan";
export const near_report_path = "/bao-cao-gan-day";
export const video_path = "/video";
export const general_new_path = "/tin-tuc-tong-hop";
