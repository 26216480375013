import { Get } from "../utils/httpProvider";
import { consoleLog } from "../utils/helper";
import { GET_ALL_MENU, GET_MENU } from "../utils/constants";
import {
  API_GET_MENU,
  API_GET_MENU_BY_ID,
  API_GET_MENU_BY_SLUG,
} from "../utils/apiUrl";

export const getMenuAction = async (model, dispatch, token = null) => {
  return Get(API_GET_MENU, null, model, token, null)
    .then((data) => {
      dispatch({
        type: GET_ALL_MENU,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const getMenuByIdAction = async (id, dispatch, token = null) => {
  return Get(API_GET_MENU_BY_ID, id, null, token, null)
    .then((data) => {
      dispatch({
        type: GET_MENU,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const getMenuBySlugAction = async (slug, dispatch, token = null) => {
  return Get(API_GET_MENU_BY_SLUG, slug, null, token, null)
    .then((data) => {
      dispatch({
        type: GET_MENU,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};
