import { isEmptyObject } from "../utils/helper";
import {
  GET_POST_PRIVATE_ALL_PAGING,
  GET_POST_PRIVATE_BY_CATE_ALL_PAGING,
  GET_POST_PRIVATE_BY_SLUG,
} from "../utils/constants";

export const postPrivateState = {
  listPostPrivate: [],
  totalGetAll: 0,
  listPostPrivateByCate: [],
  totalGetAllByCate: 0,
  postPrivateItem: null,
  postStatus: 0,
};

export function postPrivateReducer(state, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_POST_PRIVATE_ALL_PAGING:
      return {
        ...state,
        listPostPrivate:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : [],
        totalGetAll:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.totalRecords
            : 0,
      };
    case GET_POST_PRIVATE_BY_CATE_ALL_PAGING:
      return {
        ...state,
        listPostPrivateByCate:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : [],
        totalGetAllByCate:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.totalRecords
            : 0,
      };
    case GET_POST_PRIVATE_BY_SLUG:
      return {
        ...state,
        postPrivateItem:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : null,
        postStatus:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.codeStatus
            : 0,
      };
    default:
      return state;
  }
}
