import MainLayout from "./components/layout/MainLayout";
import PageSpinner from "./components/PageSpinner";
import GAListener from "./components/GAListener";
import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import {
  vibiz_homepage_path,
  vibiz_login_path,
  profile_path,
  change_password_path,
  forgot_password,
  reset_password,
} from "./utils/pathLocation";
import { Store } from "./stores/Store";
import { getDataHomePage } from "./actions/homePageAction";
import {
  refreshTokenAction,
  loginEmailAction,
  setTokenAction,
} from "./actions/authAction";
import { REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY } from "./utils/define";

const Home = React.lazy(() => import("./pages/Home"));
const Content = React.lazy(() => import("./pages/Content"));
const Login = React.lazy(() => import("./pages/Login"));
const Account = React.lazy(() => import("./components/account/Account"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));

const App = (props) => {
  const { rootState, dispatch } = useContext(Store);
  const location = useLocation();

  const userLocal = localStorage.getItem(USER_KEY);
  const refreshTokenLocal = localStorage.getItem(REFRESH_TOKEN_KEY);
  const tokenLocal = localStorage.getItem(TOKEN_KEY);

  const user = userLocal ? JSON.parse(userLocal) : null;
  const refresh_token = refreshTokenLocal
    ? JSON.parse(refreshTokenLocal)
    : null;
  const token = tokenLocal ? JSON.parse(tokenLocal) : null;

  useEffect(() => {
    getDataHomePage(dispatch);

    var dateNow = new Date();

    if (user && dateNow.getTime() / 1000 > user.exp) {
      if (refresh_token && dateNow < refresh_token.expires * 1000) {
        let model = {
          accessToken: token,
          refreshToken: refresh_token.token,
        };
        refreshTokenAction(model, dispatch);
      } else {
        localStorage.clear();
      }
    } else {
      setTokenAction(token, dispatch);
    }
  }, []);

  useEffect(() => {
    const paramUrl = new URLSearchParams(location.search.substring(1));
    let email = paramUrl.get("e");
    let passTemp = paramUrl.get("pt");
    if (user == null) {
      if (email != null && passTemp != null) {
        let model = {
          email,
          passTemp,
        };
        loginEmailAction(model, dispatch);
      }
    }
  }, [location]);

  return (
    <GAListener>
      <Switch>
        <React.Suspense fallback={<PageSpinner />}>
          <Route exact path={vibiz_login_path} component={Login} />
          <Route exact path={forgot_password} component={ForgotPassword} />
          <Route exact path={reset_password} component={ResetPassword} />
          {location.pathname !== vibiz_login_path &&
            location.pathname !== forgot_password &&
            location.pathname !== reset_password ? (
            <MainLayout>
              <Route exact path={profile_path} component={Account} />
              <Route exact path={change_password_path} component={Account} />
              <Route exact path={vibiz_homepage_path} component={Home} />
              {
                location.pathname !== profile_path &&
                location.pathname !== change_password_path && (
                  <Switch>
                    <Route exact path={`/:slug`} component={Content} />
                    <Route exact path={`*/:slugParent/:slug`} component={Content} />
                  </Switch>
                )
              }

            </MainLayout>
          ) : null}
        </React.Suspense>
        <Redirect to="/" />
      </Switch>
    </GAListener>
  );
};

export default App;
