import { isEmptyObject } from "../utils/helper";
import {
  GET_CATEGORY_BY_GROUPID,
} from "../utils/constants";

export const categoryState = {
  listCateByGroup: [],
};

export function categoryReducer(state, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_CATEGORY_BY_GROUPID:
      return {
        ...state,
        listCateByGroup:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload
            : [],
      };
    default:
      return state;
  }
}
