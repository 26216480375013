import { GET_MENU, GET_ALL_MENU } from "../utils/constants";
import { ID_EMPTY_MONGO } from "../utils/define";
import { isEmptyObject } from "../utils/helper";

export const menuState = {
  menus: [],
  postMenu: null,
};

export function menuReducer(state, action) {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case GET_ALL_MENU:
      return {
        ...state,
        menus:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload
            : [],
      };
    case GET_MENU:
      return {
        ...state,
        postMenu:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload
            : null,
      };
    default:
      return state;
  }
}
