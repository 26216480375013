import { isEmptyObject } from "../utils/helper";
import { GET_HOT_NEWS } from "../utils/constants";

export const contentState = {
  hotNews: [],
};

export function contentReducer(state, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_HOT_NEWS:
      return {
        ...state,
        hotNews:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload
            : [],
      };
    default:
      return state;
  }
}
