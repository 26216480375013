import { isEmptyObject } from "../utils/helper";
import {
  GET_DOCUMENT_PRIVATE_BY_SLUG,
  GET_DOCUMENT_PRIVATE_ALL_PAGING,
} from "../utils/constants";

export const documentPrivateState = {
  listDocumentPrivate: [],
  total: 0,
  documentPrivateItem: null,
  documentStatus: 0,
};

export function documentPrivateReducer(state, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_DOCUMENT_PRIVATE_ALL_PAGING:
      var listDocumentPrivate =
        action.payload && !isEmptyObject(action.payload)
          ? action.payload.data
          : [];
      var total =
        action.payload && !isEmptyObject(action.payload)
          ? action.payload.totalRecords
          : 0;
          var a = state;
      return {
        ...state,
        listDocumentPrivate: listDocumentPrivate,
        total: total,
      };
    case GET_DOCUMENT_PRIVATE_BY_SLUG:
      return {
        ...state,
        documentPrivateItem:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : null,
        documentStatus:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.codeStatus
            : 0,
      };
    default:
      return state;
  }
}
