import { TOKEN_KEY } from "../utils/define";
import { BaseGatewayImage, BaseGatewayImageInfo, BaseGatewayImageVNNoSSL } from "../utils/apiUrl";
export function consoleLog(message) {
  console.log(message);
}
export function GetToken() {
  var tokenValue = JSON.parse(sessionStorage.getItem(TOKEN_KEY));
  return tokenValue && tokenValue.expires_at !== null
    ? tokenValue.access_token
    : "";
}

export function subInput(text, maxLenght) {
  if (text) {
    if (text.length > maxLenght) {
      var trimmedString = text.substr(0, maxLenght);
      trimmedString =
        trimmedString.substr(
          0,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
        ) + "...";
      return trimmedString;
    } else {
      return text;
    }
  }
}

export function getImageLink(link) {
  if (link.includes(BaseGatewayImageInfo)) {
    link = link.replace(BaseGatewayImageInfo, "").trim();
  } else if (link.includes(BaseGatewayImage)) {
    link = link.replace(BaseGatewayImage, "").trim();
  } else if (link.includes(BaseGatewayImageVNNoSSL)) {
    link = link.replace(BaseGatewayImageVNNoSSL, "").trim();
  } else {
    return link
  }
  return BaseGatewayImage + link;
}


export const validInput = (type, text) => {
  if (type.toLowerCase() === "text") {
    let regexp = /([^\s])/;
    let checkingResult = regexp.exec(text);
    return checkingResult !== null ? true : false;
  }
  if (type.toLowerCase() === "password") {
    // let regexp = /^([a-zA-Z0-9]{8,})$/;
    let regexp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let checkingResult = regexp.exec(text);
    return checkingResult !== null ? true : false;
  }
  if (type.toLowerCase() === "number") {
    return true;
  }
  if (type.toLowerCase() === "phone") {
    let regexp = /^([0|\+[0-9]{1,5})?([0-9]{10,12})$/;
    let checkingResult = regexp.exec(text);
    return checkingResult !== null ? true : false;
  }
  if (type.toLowerCase() === 'email') {
    var pattern =
      /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/;
    return pattern.test(text);
  }
  return true;
};

export const getIdYoutube = (url) => {
  var regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);

  console.log(url);

  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return "error";
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const string_to_slug = (str) => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, "a");
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, "e");
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, "i");
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, "o");
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, "u");
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, "y");
  str = str.replace(/(đ)/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, "-");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dự - ở đầu
  str = str.replace(/^-+/g, "");

  // xóa phần dư - ở cuối
  str = str.replace(/-+$/g, "");

  // return
  return str;
};

export const isValidUrl = (_string) => {
  let url_string;
  try {
    url_string = new URL(_string);
  } catch (_) {
    return false;
  }
  return true;
}
