import { isEmptyObject } from "../utils/helper";
import { GET_VIDEO, GET_ALL_VIDEO } from "../utils/constants";

export const videoState = {
  videos: [],
  video: null,
};

export function videoReducer(state, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_ALL_VIDEO:
      return {
        ...state,
        videos:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : [],
      };
    case GET_VIDEO:
      return {
        ...state,
        video:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload
            : null,
      };
    default:
      return state;
  }
}
