// const BaseGateway = "http://app.vibiz.info/api/v1/";
const BaseGateway = "https://api.vibiz.vn/api/v1/";
export const BaseGatewayImage = "https://api.vibiz.vn";
// export const BaseGatewayImage = 'http://localhost:5000';
// const BaseGateway = 'http://localhost:5000/api/v1/';
export const BaseGatewayImageInfo = "http://app.vibiz.info";
export const BaseGatewayImageVNNoSSL = "http://api.vibiz.vn";
//auth
export const LOGIN = BaseGateway + "auth/authenticate";
export const REFRESH_TOKEN = BaseGateway + "auth/refresh-token";
export const LOGIN_EMAIL = BaseGateway + "auth/authenticate-email";

//menu
export const API_GET_MENU = BaseGateway + "menu/GetAll";
export const API_GET_MENU_BY_DATATYPE = BaseGateway + "menu/GetByDataType";
export const API_GET_MENU_BY_ID = BaseGateway + "menu/GetById";
export const API_GET_MENU_BY_SLUG = BaseGateway + "menu/GetBySlug";

//slide video
export const API_GET_SLIDE_VIDEO = BaseGateway + "video/GetAllPaging";
export const API_GET_VIDEO_BY_ID = BaseGateway + "video/GetById";
export const API_GET_VIDEO_BY_SLUG = BaseGateway + "video/GetBySlug";

//post publish
export const API_GET_POST_PUBLISH = BaseGateway + "postPublish/GetAllPaging";
export const API_GET_POST_PUBLISH_BY_ID = BaseGateway + "postPublish/GetById";
export const API_GET_POST_PUBLISH_BY_SLUG =
  BaseGateway + "postPublish/GetBySlug";
export const API_GET_HOT_NEWS_PUBLISH = BaseGateway + "postPublish/GetHotNews";

//category
export const API_GET_ALL_CATE = BaseGateway + "Category/GetAll";
export const API_GET_CATE_BY_GROUP_ID =
  BaseGateway + "Category/GetCateByGroupId";
export const API_GET_CATE_CUSTOMER = BaseGateway + "Category/CateCustomer";

//image slide
export const API_GET_IMAGE_SLIDE = BaseGateway + "slide/GetAllPaging";

//account
export const API_GET_ACCOUNT_BY_ID = BaseGateway + "account/GetById";

//partner
export const API_GET_PARTNER = BaseGateway + "partner/GetAllPaging";

//general news
export const API_GET_GENERAL_NEWS = BaseGateway + "generalNews/GetAllPaging";

//post private
export const API_GET_ALL_PAGING_POST_PRIVATE =
  BaseGateway + "PostPrivate/GetAllPaging";
export const API_GET_POST_PRIVATE_BY_ID = BaseGateway + "PostPrivate/GetById";
export const API_GET_POST_PRIVATE_BY_SLUG =
  BaseGateway + "PostPrivate/GetBySlug";
export const API_GET_ALL_PAGING_BY_CATE_POST_PRIVATE =
  BaseGateway + "PostPrivate/GetAllPagingByCate";
export const API_GET_HOT_NEWS_PRIVATE = BaseGateway + "PostPrivate/GetHotNews";

//document private
export const API_GET_DOCUMENT_PRIVATE =
  BaseGateway + "documentPrivate/GetAllPaging";
export const API_GET_DOCUMENT_PRIVATE_BY_SLUG =
  BaseGateway + "documentPrivate/GetBySlug";

// industry
export const API_GET_INDUSTRY = BaseGateway + "Industry/GetAllPaging";
export const API_GET_INDUSTRY_BY_ID = BaseGateway + "Industry/GetById";
export const API_GET_INDUSTRY_BY_SLUG = BaseGateway + "Industry/GetBySlug";

//Data home page
export const API_GET_DATA_HOME_PAGE =
  BaseGateway + "HomepageContent/GetDataHomePage";

// api account

export const API_GET_PROFILE = BaseGateway + "Account/GetById";
export const API_UPDATE_PROFILE = BaseGateway + "Account/Update";
export const API_CHANGE_PASSWORD = BaseGateway + "Auth/ChangePassword";
export const API_FORGOT_PASSWORD = BaseGateway + "Auth/ForgotPassword";
export const API_RESET_PASSWORD = BaseGateway + "Auth/ResetPassword";

//api contact

export const API_POST_CONTACT = BaseGateway + "Contact/Create";
