export const GET_SLIDE = "GET_SLIDE";
export const GET_INTRODUCE = "GET_INTRODUCE";
export const GET_PARTNER = "GET_PARTNER";
export const GET_GENERAL_NEWS = "GET_GENERAL_NEWS";
export const GET_SOLUTION = "GET_SOLUTION";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_HEADER = "GET_HEADER";
export const GET_FOOTER = "GET_FOOTER";
export const GET_SOCIAL_LINK = "GET_SOCIAL_LINK";
export const GET_CALL_TO_ACTION = "GET_CALL_TO_ACTION";
export const GET_RECENT_POST = "GET_RECENT_POST";
export const GET_RECENT_REPORT = "GET_RECENT_REPORT";
export const GET_MESSAGE_PERMISSION_NEWS = "GET_MESSAGE_PERMISSION_NEWS";
export const GET_DATA_HOME_PAGE = "GET_DATA_HOME_PAGE";

//video
export const GET_ALL_VIDEO = "GET_ALL_VIDEO";
export const GET_VIDEO = "GET_VIDEO";

//menu
export const GET_ALL_MENU = "GET_ALL_MENU";
export const GET_MENU = "GET_MENU";

//menu
export const GET_PAGING_POST_PUBLISH = "GET_PAGING_POST_PUBLISH";
export const GET_POST_PUBLISH = "GET_POST_PUBLISH";

//page
export const GET_POST = "GET_POST";
export const SET_POST = "SET_POST";
export const SET_CATEGORY = "SET_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_CONTENT_CATEGORY_LIST = "GET_CONTENT_CATEGORY_LIST";
export const GET_CONTENT_POST_LIST = "GET_CONTENT_POST_LIST";
export const GET_CONTENT_CATEGORY = "GET_CONTENT_CATEGORY";
export const GET_PERSONNEL_LIST = "GET_PERSONNEL_LIST";
export const GET_POST_NEW_LIST = "GET_POST_NEW_LIST";
export const SET_CONTENT_POST_LIST = "SET_CONTENT_POST_LIST";
export const GET_MOST_VIEW = "GET_MOST_VIEW";

// infor profile
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const HAS_STATUS_PROFILE = "HAS_STATUS_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_ERR = "CHANGE_PASSWORD_ERR";

// industry
export const GET_ALL_INDUSTRY = "GET_ALL_INDUSTRY";
export const GET_INDUSTRY = "GET_INDUSTRY";

//post private
export const GET_POST_PRIVATE_ALL_PAGING = "GET_POST_PRIVATE_ALL_PAGING";
export const GET_POST_PRIVATE_BY_CATE_ALL_PAGING =
  "GET_POST_PRIVATE_BY_CATE_ALL_PAGING";
export const GET_POST_PRIVATE_BY_ID = "GET_POST_PRIVATE_BY_ID";
export const GET_POST_PRIVATE_BY_SLUG = "GET_POST_PRIVATE_BY_SLUG";
export const GET_HOT_NEWS = "GET_HOT_NEWS";

//category
export const GET_CATEGORY_BY_GROUPID = "GET_CATEGORY_BY_GROUPID";

// personnel
export const GET_ALL_PERSONNEL = "GET_ALL_PERSONNEL";
export const GET_PERSONNEL = "GET_PERSONNEL";

//Auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const SET_TOKEN = "SET_TOKEN";
//post private
export const GET_DOCUMENT_PRIVATE_ALL_PAGING =
  "GET_DOCUMENT_PRIVATE_ALL_PAGING";
export const GET_DOCUMENT_PRIVATE_BY_SLUG = "GET_DOCUMENT_PRIVATE_BY_SLUG";

//Theme constant
export const NOTIFICATION_SYSTEM_STYLE = {
  NotificationItem: {
    DefaultStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      borderRadius: "4px",
      fontSize: "14px",
    },

    success: {
      borderTop: 0,
      backgroundColor: "#45b649",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderTop: 0,
      backgroundColor: "#f85032",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderTop: 0,
      backgroundColor: "#ffd700",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    info: {
      borderTop: 0,
      background: "linear-gradient(to right, #6a82fb, #fc5c7d)",
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },
  },

  Title: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      paddingRight: 5,
      color: "#fff",
      display: "inline-flex",
      fontSize: 20,
      fontWeight: "bold",
      // left: '15px',
      // position: 'absolute',
      // top: '50%',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      display: "block",
      color: "#fff",
      width: "100%",
    },
  },

  Dismiss: {
    DefaultStyle: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "inherit",
      fontSize: 20,
      color: "#f2f2f2",
      position: "relative",
      margin: 0,
      padding: 0,
      background: "none",
      borderRadius: 0,
      opacity: 1,
      width: 20,
      height: 20,
      textAlign: "initial",
      float: "none",
      top: "unset",
      right: "unset",
      lineHeight: "inherit",
    },
  },

  Action: {
    DefaultStyle: {
      background: "#fff",
      borderRadius: "2px",
      padding: "6px 20px",
      fontWeight: "bold",
      margin: "10px 0 0 0",
      border: 0,
    },

    success: {
      backgroundColor: "#45b649",
      color: "#fff",
    },

    error: {
      backgroundColor: "#f85032",
      color: "#fff",
    },

    warning: {
      backgroundColor: "#ffd700",
      color: "#fff",
    },

    info: {
      backgroundColor: "#00c9ff",
      color: "#fff",
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};
