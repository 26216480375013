import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Store } from "../../stores/Store";
import { logoutAction } from "../../actions/authAction";
import {
  profile_path,
  vibiz_homepage_path,
  vibiz_login_path,
} from "../../utils/pathLocation";
import { USER_KEY } from "../../utils/define";

const Header = () => {
  const { rootState, dispatch } = useContext(Store);
  const history = useHistory();
  const { header } = rootState.homePage;
  const { detailProfile } = rootState.account;

  const user = JSON.parse(localStorage.getItem(USER_KEY));

  useEffect(() => {
    if (header === null) {
    }
  }, []);

  let email = "";
  let contact = "";

  if (header) {
  }

  function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  const handleLogout = () => {
    logoutAction(dispatch);
    history.replace(vibiz_homepage_path);
  };

  return (
    <div id="module1" className="ModuleWrapper">
      <div className="header-vibiz">
        <div id="column1-1" className="column1 container ">
          <div className="columns-widget row">
            <div className="col-md-12">
              <div id="module2" className="ModuleWrapper">
                <div className="header-type5">
                  <div className="nav-header">
                    <div className="overflow pull-left">
                      <div className="display-table ">
                        <div className="display-cell hotline-top mr-10">
                          <span className="icon">
                            <i className="fa fa-phone" />
                            <span className="title">Liên hệ: </span>
                            {contact}
                          </span>
                        </div>
                        <div className="sep">|</div>
                        <div className="display-cell mr-10 email-top">
                          <span className="icon">
                            <i className="fa fa-envelope" />
                            <span className="title">Email: info@vibiz.vn</span>
                            {email}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="member pull-right">
                      {user ? (
                        <ul className="list-inline">
                          <li>
                            <i className="fa fa-user"></i>{" "}
                            <span>Chào:&nbsp;</span>
                            <Link to={profile_path}>
                              {user ? user.email.toLowerCase() : ""}
                            </Link>
                          </li>
                          <li>
                            <span className="divider-line">|</span>
                          </li>
                          <i className="fa fa-sign-out"></i>
                          <li className="cursor-pointer">
                            <Link title="Thoát" onClick={handleLogout}>
                              Thoát
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        <ul className="list-inline">
                          <li className="cursor-pointer">
                            <Link title="Đăng nhập" to={vibiz_login_path}>
                              Đăng nhập
                            </Link>
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div role="separator" className="divider" />
                  <div className="content-header">
                    <h1 className="title-site" style={{ display: "none" }}>
                      Vietnam Market Research and Analysis for Industries,
                      Countries, and Consumers
                    </h1>
                    <h2 className="brief-site" style={{ display: "none" }} />
                    <div>
                      <div className="banner"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
