import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Store } from "../../stores/Store";
import logo from "../../assets/img/logo64.png";
import { createUrlWithParam } from "../../utils/url";
import { GET_MENU } from "../../utils/constants";
import { getMenuAction } from "../../actions/menuAction";
import { postDataType, USER_KEY } from "../../utils/define";
import { isBrowser } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

const Menu = () => {
  const { rootState, dispatch } = useContext(Store);
  const { menus } = rootState.menu;
  const location = useLocation();
  const { slug } = useParams();
  const navbarRef = useRef();
  const [openSearch, setOpenSearch] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);

  const user = JSON.parse(localStorage.getItem(USER_KEY));
  const isTablet = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    setOpenSearch(false);
  }, [location]);

  useEffect(() => {
    let model = {
      keyword: "",
      pageIndex: 1,
      pageSize: 50,
    };
    getMenuAction(model, dispatch, GET_MENU);
  }, []);

  let menusTree = [];
  if (menus && menus.length > 0) {
    let menuData = !user
      ? menus.filter((x) => x.dataType !== postDataType.report)
      : menus;
    let mainMenus = menuData.filter(function (x) {
      return x.parentid === "";
    });
    mainMenus.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
    mainMenus.forEach(function (element) {
      menusTree.push({
        main: element,
        content: menus
          .filter((x) => x.parentid === element.id)
          .sort((a, b) => {
            return a.orderNumber - b.orderNumber;
          }),
      });
    });
  }

  function genUriMenu(slug = "", slugParent = "") {
    let uri = "";
    if (slugParent.length > 0) {
      uri = `/${slugParent}/${slug}`;
    } else {
      uri = `/${slug}`;
    }
    return uri;
  }

  const handleNavbar = () => {
    if (isBrowser || isTablet) return;
    if (navbarRef.current.style.display !== "block") {
      navbarRef.current.style.display = "block";
    } else {
      navbarRef.current.style.display = "none";
      if (menuOpen) {
        setMenuOpen(null);
      }
    }
  };

  return (
    <div>
      <div id="module3" className="ModuleWrapper">
        <nav id="menu3" className="main-menu navigation-menu-type-21">
          <div className="navbar navbar-default relative ">
            <div className="container">
              <div className="flex-sm flex-flow-rw justify-content-fs  align-items-fs">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-navbar-collapse-1"
                    aria-expanded="false"
                    onClick={() => handleNavbar()}
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" /> <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                  <Link
                    to={createUrlWithParam("/")}
                    className="nav-link-reject navbar-brand"
                    title="Vietnam Market Research and Analysis for Industries, Countries, and Consumers"
                  >
                    <img
                      src={logo}
                      alt="Vietnam Market Research and Analysis for Industries, Countries, and Consumers"
                    />
                  </Link>
                </div>
                <div id="bs-nav" className="navbar-collapse" ref={navbarRef}>
                  <div>
                    <a id="navi-close" />
                    <ul
                      className="nav navbar-nav"
                      data-orientation="horizontal"
                    >
                      {menusTree.map((item, i) => (
                        <li
                          key={i}
                          className={`item-block dropdown ${
                            menuOpen === item.main.id && "open"
                          }`}
                        >
                          <Link
                            to={genUriMenu(item.main.slug)}
                            className="nav-link-reject"
                            onClick={() => handleNavbar()}
                          >
                            <span>{item.main.name}</span>
                          </Link>
                          {item.content && item.content.length > 0 && (
                            <a
                              className="dropdown-icon"
                              data-toggle="dropdown"
                              onClick={() =>
                                setMenuOpen(
                                  menuOpen === item.main.id
                                    ? null
                                    : item.main.id
                                )
                              }
                            >
                              <span className="caret" />
                            </a>
                          )}
                          {item.content && item.content.length > 0 && (
                            <ul
                              className="dropdown-menu"
                              data-orientation="vertical"
                            >
                              {item.content.map((contentItem, i) => (
                                <li
                                  key={i}
                                  className={`item-block ${
                                    slug === contentItem.slug && "active"
                                  }`}
                                >
                                  <Link
                                    to={genUriMenu(
                                      contentItem.slug,
                                      item.main.slug
                                    )}
                                    onClick={() => handleNavbar()}
                                  >
                                    <span>{contentItem.name}</span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Menu;
