import React, { useReducer } from "react";
import useCombinedReducers from "./UseCombinedReducers";
import { homePageReducer, homePageState } from "./StoreHomePage";
import { postPublishReducer, postPublishState } from "./StorePostPublish";
import { contentReducer, contentState } from "./StoreContent";
import { accountReducer, accountState } from "./StoreAccount";
import { menuReducer, menuState } from "./StoreMenu";
import { videoReducer, videoState } from "./StoreVideo";
import { industryReducer, industryState } from "./StoreIndustry";
import { personnelReducer, personnelState } from "./StorePersonnel";
import { authReducer, authState } from "./StoreAuth";
import { postPrivateReducer, postPrivateState } from "./StorePostPrivate";
import { categoryReducer, categoryState } from "./StoreCategory";
import {
  documentPrivateReducer,
  documentPrivateState,
} from "./StoreDocumentPrivate";
export const Store = React.createContext();

export function StoreProvider(props) {
  const [rootState, dispatch] = useCombinedReducers({
    homePage: useReducer(homePageReducer, homePageState),
    postPublish: useReducer(postPublishReducer, postPublishState),
    postPrivate: useReducer(postPrivateReducer, postPrivateState),
    content: useReducer(contentReducer, contentState),
    account: useReducer(accountReducer, accountState),
    menu: useReducer(menuReducer, menuState),
    video: useReducer(videoReducer, videoState),
    industry: useReducer(industryReducer, industryState),
    personnel: useReducer(personnelReducer, personnelState),
    auth: useReducer(authReducer, authState),
    category: useReducer(categoryReducer, categoryState),
    documentPrivate: useReducer(documentPrivateReducer, documentPrivateState),
  });

  const value = { rootState, dispatch };

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
