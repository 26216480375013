import { GET_ALL_PERSONNEL, GET_PERSONNEL } from "../utils/constants";
import { isEmptyObject } from "../utils/helper";

export const personnelState = {
  listPersonnel: [],
  postPersonnel: null,
};

export function personnelReducer(state, action) {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case GET_ALL_PERSONNEL:
      return {
        ...state,
        listPersonnel:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : [],
      };
    case GET_PERSONNEL:
      return {
        ...state,
        postPersonnel:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload
            : null,
      };
    default:
      return state;
  }
}
