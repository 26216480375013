import { consoleLog } from "./helper";

function FormGet(model) {
  let formGet = [];
  for (let property in model) {
    if (Array.isArray(model[property])) {
      model[property].forEach((element) => {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(element);
        formGet.push(encodedKey + "=" + encodedValue);
      });
    } else {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(model[property]);
      formGet.push(encodedKey + "=" + encodedValue);
    }
  }
  return formGet.join("&");
}

function Header(ContentType = "", header = null, token = null) {
  let headers = new Headers();
  if (header) {
    for (var element of header.entries()) {
      headers.append(element[0], element[1]);
    }
  }
  if (token) {
    headers.append("Authorization", "bearer " + token);
  }
  if (ContentType.length > 0) {
    headers.append("Content-Type", ContentType);
  }
  return headers;
}

export function Post(
  api,
  param = null,
  model,
  token = null,
  header = null,
  convertToJson = false
) {
  let API = api;
  if (param && param.toString().length > 0) {
    API = API + "/" + param;
  }
  return fetch(API, {
    method: "POST",
    headers: Header("application/json", header, token),
    body: JSON.stringify(model),
  })
    .then((res) => {
      return convertToJson ? res.json() : res;
      // if (res.ok) return convertToJson ? res.json() : res;
    })
    .catch((error) => {
      consoleLog(error);
      return null;
    });
}

export function Get(
  api,
  param = null,
  model = null,
  token = null,
  header = null,
  convertToJson = true
) {
  let API = api;
  if (param) {
    API = API + "/" + param;
  }
  if (model) {
    API = API + "?" + FormGet(model);
  }
  return fetch(API, {
    method: "GET",
    headers: Header("application/json", header, token),
  })
    .then((res) => {
      if (convertToJson) {
        if (res.ok) return res.json();
      } else {
        return res;
      }
    })
    .catch((error) => {
      consoleLog(error);
      return null;
    });
}

export function DeleteSingle(api, param, token = null, header = null) {
  return fetch(api + "/" + param, {
    method: "DELETE",
    headers: Header("application/json", header, token),
  })
    .then((res) => res)
    .catch((error) => {
      consoleLog(error);
      return error;
    });
}

export function Put(
  api,
  param = null,
  model = null,
  token = null,
  header = null
) {
  let API = null;
  if (param && param.toString().length > 0) {
    API = api + "/" + param;
  } else {
    API = api;
  }
  return fetch(API, {
    method: "PUT",
    headers: Header("application/json", header, token),
    body: JSON.stringify(model),
  })
    .then((res) => res)
    .catch((error) => {
      consoleLog(error);
      return error;
    });
}
export async function UploadFile(
  api,
  param,
  data,
  token = null,
  header = null
) {
  let API = api;
  if (param) {
    API = API + "?" + FormGet(param);
  }
  const requestOptions = {
    method: "POST",
    body: data,
    headers: Header("", header, token),
  };
  return await fetch(API, requestOptions)
    .then(async (res) => res)
    .catch((error) => {
      consoleLog(error);
      return error;
    });
}
