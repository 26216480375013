import { GET_ALL_INDUSTRY, GET_INDUSTRY } from "../utils/constants";
import { isEmptyObject } from "../utils/helper";

export const industryState = {
  listIndustry: [],
  postIndustry: null,
};

export function industryReducer(state, action) {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case GET_ALL_INDUSTRY:
      return {
        ...state,
        listIndustry:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : [],
      };
    case GET_INDUSTRY:
      return {
        ...state,
        postIndustry:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload
            : null,
      };
    default:
      return state;
  }
}
