import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../stores/Store";
import { createUrlWithParam } from "../../utils/url";
import { postDataType } from "../../utils/define";
const FooterBottom = () => {
  const { rootState, dispatch } = useContext(Store);
  const { socialLink } = rootState.homePage;
  const { menus } = rootState.menu;
  useEffect(() => {}, []);

  let faceLink;
  let googleLink;
  let instagamLink;
  let youtubeLink;
  let twitterLink;

  if (socialLink) {
  }
  const genUrl = (item) => {
    let baseUrl = "";
    let menuParentCate = menus?.find(
      (x) => x.dataType === postDataType.introduce
    );
    let menuChild = menus?.find((x) => x.dataType === postDataType.termOfUser);
    if (menuParentCate && menuChild) {
      baseUrl = `${menuParentCate.slug}/${menuChild.slug}`;
    }
    return createUrlWithParam(baseUrl);
  };
  return (
    <div>
      <div id="module14" className="ModuleWrapper">
        <div className="copy-right">
          <div id="column1-14" className="column1 container ">
            <div className="columns-widget row">
              <div className="col-md-12">
                <div id="module15" className="ModuleWrapper">
                  <div className="copy-right">
                    <div className="columns-widget columns9-3 ">
                      <div className="row">
                        <div className="col-left col-xs-12 col-md-9 col-sm-8">
                          <div>
                            <div id="module17" className="ModuleWrapper" />
                            <div id="module18" className="ModuleWrapper">
                              <div className="foo-cr">
                                <p>
                                  <span style={{ color: "#bbb" }}>
                                    2021 © Đăng ký bản quyền bởi Vibiz
                                  </span>
                                  <a href={genUrl()}>
                                    <span style={{ color: "#15b1f3" }}>
                                      Điều khoản sử dụng
                                    </span>
                                  </a>
                                  {/* <span style={{ color: '#15b1f3' }}>
                                  </span>|
                                  <a href="/gioi-thieu/chinh-sach-bao-mat">
                                    <span style={{ color: '#15b1f3' }}>Chính sách bảo mật</span>
                                  </a> */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-left col-xs-12 col-md-9 col-sm-8">
                          <div id="module17" className="ModuleWrapper" />
                          <div id="module18" className="ModuleWrapper">
                            {socialLink && (
                              <div
                                className="foo-cr"
                                dangerouslySetInnerHTML={{
                                  __html: socialLink.content
                                }}
                              />
                            )}
                          </div>
                        </div> */}
                        <div className="col-right col-xs-12 col-md-3 col-sm-4">
                          <div id="module16" className="ModuleWrapper">
                            <div className="social-snippet-type-3">
                              {/* <ul className="list-inline">
                                <li>
                                  <Link to={faceLink ? faceLink : ""}>
                                    <i className="fa fa-facebook" />
                                  </Link>
                                  <a href={faceLink ? faceLink : ""}>
                                    <i className="fa fa-facebook" />
                                  </a>
                                </li>
                                <li>
                                  <Link to={twitterLink ? twitterLink : ""}>
                                    <i className="fa fa-twitter" />
                                  </Link>
                                  <a href={twitterLink ? twitterLink : ""}>
                                    <i className="fa fa-twitter" />
                                  </a>
                                </li>
                                <li>
                                  <Link to={googleLink ? googleLink : ""}>
                                    <i className="fa fa-google" />
                                  </Link>
                                  <a href={googleLink ? googleLink : ""}>
                                    <i className="fa fa-google" />
                                  </a>
                                </li>
                                <li>
                                  <Link to={youtubeLink ? youtubeLink : ""}>
                                    <i className="fa fa-youtube" />
                                  </Link>
                                  <a href={youtubeLink ? youtubeLink : ""}>
                                    <i className="fa fa-youtube" />
                                  </a>
                                </li>
                                <li>
                          <Link to={instagamLink}>
                            <i className="fa fa-linkedin" />
                          </Link>
                        </li>
                              </ul> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
