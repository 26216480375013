import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REFRESH_TOKEN_SUCCESS,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_ERROR,
  SET_TOKEN,
} from "../utils/constants";
import { consoleLog } from "../utils/helper";
import {
  LOGIN,
  REFRESH_TOKEN,
  API_FORGOT_PASSWORD,
  API_RESET_PASSWORD,
  LOGIN_EMAIL,
} from "../utils/apiUrl";
import { Post } from "../utils/httpProvider";

export const loginAction = async (model, dispatch) => {
  return Post(LOGIN, null, model, null)
    .then((res) => {
      res.json().then((data) => {
        if (res.ok) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: data.errors,
          });
        }
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const loginEmailAction = async (model, dispatch) => {
  return Post(LOGIN_EMAIL, null, model, null)
    .then((res) => {
      res.json().then((data) => {
        if (res.ok) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: data.errors,
          });
        }
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const forgotPassWordAction = async (model, dispatch) => {
  return Post(API_FORGOT_PASSWORD, null, model, null)
    .then((res) => {
      if (res.ok) {
        dispatch({
          type: FORGOT_PASSWORD,
          payload: res.status,
        });
      } else {
        if (res.status === 400) {
          res.json().then((data) => {
            dispatch({
              type: FORGOT_PASSWORD_ERROR,
              payload: data.errors,
            });
          });
        }
      }
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const resetPassWordAction = async (model, dispatch) => {
  return Post(API_RESET_PASSWORD, null, model, null)
    .then((res) => {
      if (res.ok) {
        dispatch({
          type: RESET_PASSWORD,
          payload: res.status,
        });
      } else {
        res.json().then((data) => {
          dispatch({
            type: RESET_PASSWORD_ERROR,
            payload: data.errors,
          });
        });
      }
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const refreshTokenAction = async (dispatch) => {
  return Post(REFRESH_TOKEN, null, null, null)
    .then((data) => {
      dispatch({
        type: REFRESH_TOKEN_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const logoutAction = async (dispatch) => {
  return dispatch({
    type: LOGOUT,
    payload: null,
  });
};

export const setTokenAction = async (token, dispatch) => {
  return dispatch({
    type: SET_TOKEN,
    payload: token,
  });
};
