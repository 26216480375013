export const createUrlWithParam = (baseUrl = '', params = {}) => {
  let temp = [];

  for (let property in params) {
    if (Array.isArray(params[property])) {
      params[property].forEach(element => {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(element);
        temp.push(encodedKey + '=' + encodedValue);
      });
    } else {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      temp.push(encodedKey + '=' + encodedValue);
    }
  }

  let strParams = temp.join('&');

  return strParams.length > 0 ? baseUrl + '?' + strParams : baseUrl;
};

export const genUri = (json = '') => {
  let data = null;
  if (json.length > 0) {
    data = JSON.parse(json);
  }
  return data && data.link ? data.link : '';
};