import { Get } from "../utils/httpProvider";
import { consoleLog } from "../utils/helper";
import {
  GET_CATEGORY_BY_GROUPID
} from "../utils/constants";

import {
  API_GET_CATE_BY_GROUP_ID
} from "../utils/apiUrl";

// lấy danh sách tất cả cate thuộc groupId
export const getCateByGroupIdAction = async (groupIds, dispatch, token = null) => {
  let model = {
    groupIds,
  };
  return Get(API_GET_CATE_BY_GROUP_ID, null, model, token, null)
    .then((data) => {
      dispatch({
        type: GET_CATEGORY_BY_GROUPID,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

