// Các action lấy dữ liệu cho màn hình trang chủ: header, menu, silde, partner, footer ...
// Lưu ý đặt tên cho đúng
import { Get } from "../utils/httpProvider";
import { consoleLog } from "../utils/helper";
import {
  GET_SLIDE,
  GET_VIDEO,
  GET_GENERAL_NEWS,
  GET_INTRODUCE,
  GET_SOLUTION,
  GET_CUSTOMER,
  GET_HEADER,
  GET_RECENT_REPORT,
  GET_PARTNER,
  GET_DATA_HOME_PAGE,
} from "../utils/constants";
import {
  API_GET_POST_PUBLISH,
  API_GET_IMAGE_SLIDE,
  API_GET_PARTNER,
  API_GET_SLIDE_VIDEO,
  API_GET_DATA_HOME_PAGE,
  API_GET_ALL_PAGING_BY_CATE_POST_PRIVATE,
} from "../utils/apiUrl";

export const getImageSlideAction = async (model, dispatch, token = null) => {
  return Get(API_GET_IMAGE_SLIDE, null, model, token, null)
    .then((data) => {
      dispatch({
        type: GET_SLIDE,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const getVideoAction = async (model, dispatch, token = null) => {
  return Get(API_GET_SLIDE_VIDEO, null, model, token, null)
    .then((data) => {
      dispatch({
        type: GET_VIDEO,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const getPartnerAction = async (model, dispatch, token = null) => {
  return Get(API_GET_PARTNER, null, model, token, null)
    .then((data) => {
      dispatch({
        type: GET_PARTNER,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const getRecentReportAction = async (model, dispatch, token = null) => {
  return Get(API_GET_POST_PUBLISH, null, model, token, null)
    .then((data) => {
      dispatch({
        type: GET_RECENT_REPORT,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

export const getDataHomePage = async (dispatch, token = null) => {
  return Get(API_GET_DATA_HOME_PAGE, null, null, token, null)
    .then((data) => {
      dispatch({
        type: GET_DATA_HOME_PAGE,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};

// lấy danh sách tin tổng hợp theo category
export const getGeneralNewsAction = async (
  isPublish,
  model,
  dispatch,
  token = null
) => {
  return Get(
    isPublish ? API_GET_POST_PUBLISH : API_GET_ALL_PAGING_BY_CATE_POST_PRIVATE,
    null,
    model,
    token,
    null
  )
    .then((data) => {
      dispatch({
        type: GET_GENERAL_NEWS,
        payload: data,
      });
    })
    .catch((e) => {
      consoleLog(e);
    });
};
