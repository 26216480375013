import React, { useEffect, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Store } from "../../stores/Store";
import { getGeneralNewsAction } from "../../actions/homePageAction";
import { getCateByGroupIdAction } from "../../actions/categoryAction";
import { TOKEN_KEY, USER_KEY, postDataType } from "../../utils/define";
import { createUrlWithParam } from "../../utils/url";

const GeneralNew = (props) => {
  const { isGeneralNear } = props;
  const { rootState, dispatch } = useContext(Store);
  const { generalNews } = rootState.homePage;
  const { listCateByGroup } = rootState.category;
  const { menus } = rootState.menu;
  const { token } = rootState.auth;
  const user = JSON.parse(localStorage.getItem(USER_KEY));

  useEffect(() => {
    if (user && user.groupId && token) {
      getCateByGroupIdAction(user.groupId, dispatch, token);
    }
  }, [token]);

  useEffect(() => {
    if (token && token != "") {
      let cateMap = [];
      if (listCateByGroup && listCateByGroup.length > 0) {
        cateMap = listCateByGroup.map((x) => x.cateId);
      }
      let modelGetByCate = {
        CateIds: cateMap,
        PageIndex: 1,
        PageSize: 6,
        IsRealese: true,
      };
      if (cateMap.length > 0) {
        getGeneralNewsAction(false, modelGetByCate, dispatch, token);
      }
    } else {
      let modelGetPostPublish = {
        pageIndex: 1,
        pageSize: 6,
        dataType: postDataType.news,
        IsRealese: true,
      };
      getGeneralNewsAction(true, modelGetPostPublish, dispatch);
    }
  }, [listCateByGroup, token]);

  const genUrl = (item) => {
    let baseUrl = "";
    if (user) {
      let menuParentCate = menus?.find(
        (x) => x.dataType === postDataType.category
      );
      let catePost = listCateByGroup?.filter((x) =>
        item.cateId?.includes(x.cateId)
      );
      if (menuParentCate && catePost && catePost[0]) {
        baseUrl = `${menuParentCate.slug}/${catePost[0].slug}/${item.slug}`;
      }
    } else {
      let menuParentNew = menus?.find((x) => x.dataType === postDataType.news);
      if (menuParentNew) {
        baseUrl = `${menuParentNew.slug}/${item.slug}`;
      }
    }
    return createUrlWithParam(baseUrl);
  };
  return (
    <div className="panel-body">
      <div id="module29" className="ModuleWrapper">
        <section
          id="section29"
          className="section-list Article-News-Detail-default"
        >
          {isGeneralNear ? (
            <div>
              {generalNews &&
                generalNews.slice(0, 4).map((item, i) => (
                  <div key={i}>
                    <div className="panel-body">
                      <div id="module7" className="ModuleWrapper">
                        <section
                          id="section7"
                          className="section-list Article-Detail-listType6"
                        >
                          <div className="listType6 listType6-1 news-item">
                            <div className="article-news">
                              <Link to={genUrl(item)} title={item.title}>
                                @vibiz&nbsp;{" "}
                              </Link>
                            </div>
                            <div className="content-title">
                              <div
                                className="news-title clearfix"
                                style={{
                                  textAlign: "left !important",
                                }}
                              >
                                <div
                                  className="brief-news"
                                  style={{ marginBottom: 5 }}
                                >
                                  {item.name}
                                  <span
                                    style={{ marginLeft: 5 }}
                                    className="time-news"
                                  >
                                    {item.publishTime &&
                                      new Date(
                                        item.publishTime
                                      ).toLocaleDateString() + " "}
                                    {item.publishTime &&
                                      new Date(
                                        item.publishTime
                                      ).toLocaleTimeString()}
                                    {item.publishAtUtc &&
                                      new Date(
                                        item.publishAtUtc
                                      ).toLocaleDateString() + " "}
                                    {item.publishAtUtc &&
                                      new Date(
                                        item.publishAtUtc
                                      ).toLocaleTimeString()}
                                  </span>
                                </div>
                              </div>
                              <div className="clear-both" />
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div>
              {generalNews &&
                generalNews.map((item, i) => (
                  <div key={i}>
                    <article
                      className="list-news-one"
                      itemScope
                      itemType="http://schema.org/NewsArticle"
                    >
                      <h4 className="title" itemProp="headline">
                        <Link to={genUrl(item)} title={item.title}>
                          {item.name}
                        </Link>
                        <span style={{ marginLeft: 5 }}>
                          {item.publishTime &&
                            new Date(item.publishTime).toLocaleDateString() +
                              " "}
                          {item.publishTime &&
                            new Date(item.publishTime).toLocaleTimeString()}
                          {item.publishAtUtc &&
                            new Date(item.publishAtUtc).toLocaleDateString() +
                              " "}
                          {item.publishAtUtc &&
                            new Date(item.publishAtUtc).toLocaleTimeString()}
                        </span>
                      </h4>
                    </article>
                  </div>
                ))}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default GeneralNew;
