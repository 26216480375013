import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../stores/Store";
import GeneralNew from "../home/GeneralNew"
const Footer = () => {
  const { rootState, dispatch } = useContext(Store);
  const { recentNews, introduce, contactFooter } = rootState.homePage;

  useEffect(() => {}, []);

  return (
    <div id="module5" className="ModuleWrapper">
      <div className="sect-foot">
        <div id="column1-5" className="column1 container ">
          <div className="columns-widget row">
            <div className="col-md-12">
              <div id="module6" className="ModuleWrapper">
                <div className="columns-widget columns4-4-4 ">
                  <div className="row">
                    <div className="col-left col-xs-12 col-md-4 col-sm-4">
                      <div id="module10" className="ModuleWrapper">
                        <div className="tile-panel relative  clearfix">
                          <div className="tile-content clearfix">
                            <div
                              className="footer-address wrap-edit-footer"
                              style={{}}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="panel panel-default frame564175037f8b9a474e8b4567 frameOption2eeda083d4 panel-footer"
                        id="frameBoundB580818aced8410"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">Giới thiệu</div>
                        </div>
                        <div className="panel-body">
                          <div id="module11" className="ModuleWrapper">
                            <div>
                              <div style={{ display: "table-cell" }}>
                                <span
                                  style={{ color: "#FFFFFF" }}
                                  dangerouslySetInnerHTML={{
                                    __html: introduce
                                      ? introduce.description
                                      : "",
                                  }}
                                ></span>
                                <br />
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-center col-xs-12 col-md-4 col-sm-4">
                      <div
                        className="panel panel-default frame564175037f8b9a474e8b4567 frameOption2eeda083d4 panel-footer"
                        id="frameBoundB567a05298ecac"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">Liên hệ</div>
                        </div>
                        <div className="panel-body">
                          <div id="module8" className="ModuleWrapper">
                            <div style={{ display: "table-cell" }}>
                              <span
                                style={{ color: "#FFFFFF" }}
                                dangerouslySetInnerHTML={{
                                  __html: contactFooter
                                    ? contactFooter.description
                                    : "",
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-right col-xs-12 col-md-4 col-sm-4">
                      <div
                        className="panel panel-default frame564175037f8b9a474e8b4567 frameOption2eeda083d4 panel-footer"
                        id="frameBoundB58104af01e6a40"
                      >
                        <div className="panel-heading">
                          <div className="panel-title">Bản tin gần đây</div>
                        </div>
                        <GeneralNew
                          isGeneralNear={true}
                          />
                        {/* <div className="panel-body">
                          <div id="module7" className="ModuleWrapper">
                            <section
                              id="section7"
                              className="section-list Article-Detail-listType6"
                            >
                             
                              {recentNews &&
                                recentNews.map((news, i) => (
                                  <div
                                    key={i}
                                    className="listType6 listType6-1 news-item"
                                  >
                                    <div className="bg-listType6">
                                      <div
                                        className="row"
                                        style={{ marginBottom: 20 }}
                                      >
                                        <div className="article-news">
                                          <Link
                                            to={`/${news.slug}`}
                                            title={news.name}
                                          >
                                            @vibiz&nbsp;{" "}
                                          </Link>
                                        </div>
                                        <div className="content-title">
                                          <div
                                            className="news-title clearfix"
                                            style={{
                                              textAlign: "left !important",
                                            }}
                                          >
                                            <div
                                              className="brief-news"
                                              style={{ marginBottom: 5 }}
                                            >
                                              {news.name}{" "}
                                              <span className="time-news">
                                                {new Date(
                                                  news.post_date
                                                ).toLocaleDateString()}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clear-both" />
                                    </div>
                                  </div>
                                ))}
                            </section>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
