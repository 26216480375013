import { isEmptyObject } from "../utils/helper";
import {
  GET_SLIDE,
  GET_PARTNER,
  GET_DATA_HOME_PAGE,
  GET_RECENT_REPORT,
  GET_GENERAL_NEWS,
} from "../utils/constants";

export const homePageState = {
  imageSlides: [],
  solutionService: [],
  introduce: null,
  partners: [],
  hotNews: [],
  solutionAnalysis: [],
  customer: [],
  header: null,
  contactFooter: null,
  socialLink: null,
  callToAction: null,
  generalNews: [],
  recentReport: [],
  messagePermission: null,
};

export function homePageReducer(state, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_SLIDE:
      return {
        ...state,
        imageSlides:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data[0]?.images
            : [],
      };
    case GET_PARTNER:
      return {
        ...state,
        partners:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : [],
      };
    case GET_RECENT_REPORT:
      return {
        ...state,
        recentReport:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : [],
      };
    case GET_GENERAL_NEWS:
      return {
        ...state,
        generalNews:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : null,
      };
    case GET_DATA_HOME_PAGE:
      let solutionService = [];
      let solutionAnalysis = [];
      let callToAction = null;
      let introduce = null;
      let contactFooter = null;
      if (action.payload && !isEmptyObject(action.payload)) {
        solutionService = action.payload.find(
          (x) => x.key === "SolutionService"
        )?.value;
        introduce = action.payload.find((x) => x.key === "Introduce")?.value[0];
        callToAction = action.payload.find((x) => x.key === "Knowledge")
          ?.value[0];
        solutionAnalysis = action.payload.find(
          (x) => x.key === "SolutionAnalysis"
        )?.value;
        contactFooter = action.payload.find((x) => x.key === "ContactFooter")
          ?.value[0];
      }
      return {
        ...state,
        solutionService,
        solutionAnalysis,
        callToAction,
        introduce,
        contactFooter,
      };
    default:
      return state;
  }
}
