import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";
import FooterBottom from "./FooterBottom";
import React from "react";
import { Route } from "react-router-dom";
// import Notification from './Notification';

class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <main className="cr-app bg-light">
        <Header />
        <Menu />
        {children}
        <Footer />
        <FooterBottom />
      </main>
    );
  }
}

export default MainLayout;
