import React from "react";
import {
    GET_PROFILE,
    HAS_STATUS_PROFILE,
} from "../utils/constants";
export const StoreNews = React.createContext();

export const accountState = {
  detailProfile:null,
  isStatusAccount:false
};

export function accountReducer(state, action) {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        detailProfile: action.payload
      };
    case HAS_STATUS_PROFILE:
      return {
        ...state,
        isStatusAccount: action.payload
      };
    default:
      return state;
  }
}
