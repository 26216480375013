import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "./stores/Store";
import "./styles/css/style.css";
import "./styles/css/custome.css";
import "./styles/css/owl.carousel.css";
import "./styles/css/jquery.fancybox-2.1.5.css";
import "./styles/css/owl.theme.default.min.css";
import "./styles/css/font-awesome.min.css";
// import "./styles/css/bootstrap.min.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(
  <StoreProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
