import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_ERROR,
  SET_TOKEN,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERR

} from "../utils/constants";
import jwt_decode from "jwt-decode";
import { USER_KEY, TOKEN_KEY, REFRESH_TOKEN_KEY, role } from "../utils/define";

export const authState = {
  error: false,
  errorContent: "",
  token: null,
  codeStatusForgotPass: 0,
  detailErrorForgotPas: null,
  codeStatusResetPass: 0,
  detailErrorResetPas: null,
  codeStatusChangePass: 0,
  detailErrorChangePass: null,
};

export function authReducer(state, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case LOGIN_SUCCESS:
      let rolesNotAccess = [
        role.Admin,
        role.Manager,
        role.Publisher,
        role.Editor,
      ];
      let user = jwt_decode(action.payload.accessToken);
      if (rolesNotAccess.some((element) => user.role.includes(element.toLowerCase()))) {
        return {
          ...state,
          token: null,
          error: true,
          errorContent: "Tài khoản không hợp lệ",
        };
      }
      localStorage.setItem(USER_KEY, JSON.stringify(user));
      localStorage.setItem(
        TOKEN_KEY,
        JSON.stringify(action.payload.accessToken)
      );
      if (action.payload.refreshToken) {
        localStorage.setItem(
          REFRESH_TOKEN_KEY,
          JSON.stringify(action.payload.refreshToken)
        );
      }

      return {
        ...state,
        token: action.payload.accessToken,
        error: false,
        errorContent: "",
      };
    case LOGIN_ERROR:
      let errors = action.payload;
      if (errors.Username) {
        return {
          ...state,
          token: null,
          error: true,
          errorContent: errors.Username[0],
        };
      }
      if (errors.Password) {
        return {
          ...state,
          token: null,
          error: true,
          errorContent: errors.Password[0],
        };
      }
      return {
        ...state,
        token: null,
        error: true,
        errorContent: errors.content[0],
      };

    case REFRESH_TOKEN_SUCCESS:
      localStorage.setItem(
        USER_KEY,
        JSON.stringify(jwt_decode(action.payload.accessToken))
      );
      localStorage.setItem(
        TOKEN_KEY,
        JSON.stringify(action.payload.accessToken)
      );
      localStorage.setItem(
        REFRESH_TOKEN_KEY,
        JSON.stringify(action.payload.refreshToken)
      );
      return {
        ...state,
        token: action.payload.accessToken,
        error: false,
        errorContent: "",
      };
    case LOGOUT:
      localStorage.clear();
      return { ...state, token: null, error: false, errorContent: "" };
    case FORGOT_PASSWORD:
      return { ...state, codeStatusForgotPass: action.payload };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, detailErrorForgotPas: action.payload };
    case RESET_PASSWORD_ERROR:
      return { ...state, detailErrorResetPas: action.payload };
    case RESET_PASSWORD:
      return { ...state, codeStatusResetPass: action.payload };
    case CHANGE_PASSWORD_ERR:
      return { ...state, detailErrorChangePass: action.payload };
    case CHANGE_PASSWORD:
      return { ...state, codeStatusChangePass: action.payload };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    default:
      return state;
  }
}
