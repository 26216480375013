import { isEmptyObject } from "../utils/helper";
import { GET_PAGING_POST_PUBLISH, GET_POST_PUBLISH } from "../utils/constants";

export const postPublishState = {
  listPostPublish: [],
  total: 0,
  postPublish: null,
  postStatus: 0,
};

export function postPublishReducer(state, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_PAGING_POST_PUBLISH:
      return {
        ...state,
        listPostPublish:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : [],
        total:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.totalRecords
            : 0,
      };
    case GET_POST_PUBLISH:
      return {
        ...state,
        postPublish:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.data
            : null,
        postStatus:
          action.payload && !isEmptyObject(action.payload)
            ? action.payload.codeStatus
            : 0,
      };
    default:
      return state;
  }
}
