import { tag_path, term_of_user_path, near_report_path } from "./pathLocation";

export const TOKEN_KEY = "auth_web_vibiz";
export const REFRESH_TOKEN_KEY = "refresh_auth_web_vibiz";
export const USER_KEY = "web_user";

export const PostStatusOpen = "open";
export const PostStatusDraft = "draft";

export const objectStatusPost = [
  { text: "Đã duyệt", value: "open" },
  { text: "Chưa duyệt", value: "draft" },
  { text: "Đã xóa", value: "closed" },
];

export const objectShowNumber = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const objectTypeInput = [
  { text: "Text", value: "text" },
  { text: "Html", value: "html" },
];

export const breadcrumbList = [
  { path: tag_path, name: "Tìm kiếm theo từ khóa" },
  { path: near_report_path, name: "Báo cáo gần đây" },
  { path: term_of_user_path, name: "Điều khoản sử dụng" },
];

export const lstGender = [
  {
    text: "Nam",
    value: "M",
  },
  {
    text: "Nữ",
    value: "F",
  },
  {
    text: "Khác",
    value: "O",
  },
];

export const DATE_FORMAT = "dd/MM/yyyy";
export const CODE_UNAUTHORIZED = 401;
export const CODE_OK = 200;

export const post_ref = "direct";
export const page_size = 15;

export const ID_EMPTY_MONGO = "000000000000000000000000";

export const postDataType = {
  category: "category",
  news: "news",
  personnel: "personnel",
  report: "report",
  introduce: "introduce",
  termOfUser: "termOfUser",
  contact: "contact",
};

export const accountType = {
  Manager: "Manager",
  Customer: "Customer",
};

export const role = {
  Admin: "Admin",
  Manager: "Manager ",
  Publisher: "Publisher",
  Editor: "Editor",
  Customer: "Customer",
};

export const videoSlug = "video";
export const recentReportSlug = "bao-cao-gan-day";
export const newArticleSlug = "news-article";
export const categorySlug = "category";
